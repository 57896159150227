import {
  Modal,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow
} from 'carbon-components-react'
import styles from './FilePreviewModal.module.scss'
import { Download16 } from '@carbon/icons-react'
import { useContext } from 'react'
import { RegionsContext } from '../../App'
import camelCase from 'lodash.camelcase'

const FilePreviewModal = ({
  openModal,
  handleCloseModal,
  id,
  fileName,
  rows,
  total,
  downloadFile
}: any) => {
  const regions = useContext(RegionsContext)

  let headers = [
    {
      key: 'sku',
      header: 'SKU'
    },
    {
      key: 'productName',
      header: 'Product Name'
    },
    {
      key: 'productDescription',
      header: 'Product Description'
    },
    {
      key: 'url',
      header: 'Product Page URL'
    }
  ]

  regions.forEach((region) => {
    const key = camelCase(region.csvDisplayHeader)
    if (rows.some((item: any) => item[key])) {
      headers.push({
        key,
        header: region.csvDisplayHeader
      })
    }
  })

  return (
    <Modal
      open={openModal}
      modalHeading="File Preview"
      preventCloseOnClickOutside={true}
      onRequestClose={() => handleCloseModal()}
      passiveModal
      className={styles.filePreviewModal}
    >
      <p className={styles.sub}>Previewing the first 50 of {total} items.</p>
      <div className={styles.container}>
        <div className={styles.header}>
          <p>{fileName}</p>
        </div>
        <DataTable rows={rows} headers={headers} stickyHeader={true}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps
          }: any) => (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()} size="short">
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        key={header.key}
                        {...getHeaderProps({ header })}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => (
                    <TableRow key={row.id} {...getRowProps({ row })}>
                      {row.cells.map((cell: any) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </div>
      <p className={styles.total}>Total of {total} items imported.</p>
      <div className={styles.actions}>
        <Button
          className={styles.btn}
          size="field"
          renderIcon={Download16}
          onClick={() => downloadFile(id)}
        >
          Download File
        </Button>
      </div>
    </Modal>
  )
}

export default FilePreviewModal
