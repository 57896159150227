import styles from './App.module.scss'
import { useAuth0 } from '@auth0/auth0-react'
import Login from './components/Login/Login'
import EndUserTerms from './components/EndUserTerms/EndUserTerms'
import Sidebar from './components/Sidebar/Sidebar'
import Main from './components/Main/Main'
import { Link as CarbonLink } from 'carbon-components-react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom'
import Register from './components/Register/Register'
import { useEffect, useState } from 'react'
import { getAccount, getRegions } from './lib/apiClient'
import React from 'react'
import { Account } from './types/account.type'
import { Region } from './types/region.type'
import SearchHistory from './components/SearchHistory/SearchHistory'
import AccountInfo from './components/AccountInfo/AccountInfo'
import Payment from './components/Payment/Payment'
import Open from './components/Open/Open'

const accountContextInitialValue: {
  account: Account | null
  setAccount: React.Dispatch<Account | null>
} = {
  account: null,
  setAccount: () => {}
}

const regionsContextInitialValue: Region[] = []

export const AccountContext = React.createContext(accountContextInitialValue)
export const RegionsContext = React.createContext(regionsContextInitialValue)

function App() {
  const { isLoading, user, logout } = useAuth0()
  const [account, setAccount] = useState<Account | null>(null)
  const [accountLoading, setAccountLoading] = useState(false)
  const [noAccount, setNoAccount] = useState(false)
  const accountContextValue = { account, setAccount }
  const [regions, setRegions] = useState(regionsContextInitialValue)

  const { search } = useLocation()

  const isOpen = search && search.includes('open=true')

  useEffect(() => {
    getRegions().then((result) => setRegions(result))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user && user.email) {
      setAccountLoading(true)
      getAccount(user.email)
        .then((account) => {
          setAccount(account)
        })
        .catch((_err) => {
          setNoAccount(true)
        })
        .finally(() => {
          setAccountLoading(false)
        })
    }
  }, [user])

  if (isLoading || accountLoading) {
    return <div className={styles.loadingContainer}>Loading...</div>
  }

  if (user && !user.email_verified) {
    return (
      <div className={styles.verificationContainer}>
        <p>
          A verification email has been sent to: <strong>{user.email}</strong>
        </p>
        <p>After confirming, please refresh this page.</p>
        <CarbonLink
          onClick={() => {
            logout({
              // eslint-disable-next-line no-restricted-globals
              returnTo: location.origin
            })
          }}
        >
          Sign Out
        </CarbonLink>
      </div>
    )
  }

  return (
    <>
      <div className={styles.app}>
        <AccountContext.Provider value={accountContextValue}>
          <RegionsContext.Provider value={regions}>
            <Router>
              {!user ? (
                <div className={styles.centerContainer}>
                  {isOpen ? <Open /> : <Login />}
                </div>
              ) : account ? (
                <>
                  <Sidebar />
                  <div className={styles.content}>
                    <Switch>
                      <Route path="/classifier">
                        <Main />
                      </Route>
                      <Route path="/account/info">
                        <AccountInfo />
                      </Route>
                      <Route path="/account/history">
                        <SearchHistory />
                      </Route>
                      <Route path="/account/billing">
                        <Payment />
                      </Route>
                      <Redirect to="/classifier" />
                    </Switch>
                    <EndUserTerms />
                  </div>
                </>
              ) : noAccount ? (
                <Switch>
                  {console.log('register')}
                  <Route path="/register">
                    <Register />
                  </Route>
                  <Redirect to="/register" />
                </Switch>
              ) : null}
            </Router>
          </RegionsContext.Provider>
        </AccountContext.Provider>
      </div>
    </>
  )
}

export default App
