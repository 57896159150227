const getAuthData = (user: any) => {
  const metadata = user
    ? user['https://hs-classify.app/app_metadata'] || {}
    : {}

  if (!metadata.account_type) {
    metadata.account_type = 'free'
  }
  return metadata
}

export { getAuthData }
