import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import ErrorNotificationsModal from '../ErrorNotificationsModal/ErrorNotificationsModal'
import InteractiveClassifier from '../InteractiveClassifier/InteractiveClassifier'
import SearchResult from '../SearchResult/SearchResult'
import styles from '../Main/Main.module.scss'

const Open = () => {
  return (
    <div className={styles.root}>
      <ErrorNotificationsModal />
      <div className={styles.classifier}>
        <TextBasedSearch />
      </div>
    </div>
  )
}

const TextBasedSearch = () => {
  const [data, setData] = useState(null)
  return !data ? (
    <InteractiveClassifier setData={setData} isOpen={true} />
  ) : (
    <SearchResult data={data} setData={setData} />
  )
}

export default withRouter(Open)
