import { useAuth0 } from '@auth0/auth0-react'
import { Modal } from 'carbon-components-react'
import { useContext, useState } from 'react'
import { AccountContext } from '../../App'
import { acceptTermsForAccountId } from '../../lib/apiClient'
import styles from './EndUserTerms.module.scss'

const EndUserTerms = () => {
  const { account } = useContext(AccountContext)
  const [open, setOpen] = useState(true)
  const { user, logout } = useAuth0()
  const userMetadata = user ? user['https://hs-classify.app/user_metadata'] : {}

  const acceptTerms = async () => {
    try {
      const result = await acceptTermsForAccountId(account?.id || 0)
      if (result) {
        setOpen(false)
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  const hasAcceptedTerms =
    userMetadata?.hasAcceptedTerms || account?.hasAcceptedTerms
  return !hasAcceptedTerms ? (
    <Modal
      open={open}
      modalHeading="HSCODES TERMS OF USE"
      primaryButtonText="Accept terms"
      secondaryButtonText="Log me out"
      onRequestClose={() =>
        logout({
          returnTo: origin
        })
      }
      preventCloseOnClickOutside={true}
      onSecondarySubmit={() =>
        logout({
          returnTo: origin
        })
      }
      onRequestSubmit={() => acceptTerms()}
      className={styles.modal}
    >
      <div className={styles.text}>
        <br />
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '0pt'
          }}
        >
          <span
            style={{
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            Welcome, and thank you for your interest in HSCodes.io, Inc.
            (“HSCodes”). These Terms of Use, including any mutually executed
            Order Form referencing these Terms of Use, constitute a legally
            binding agreement (the “Agreement”) between you (the “Company”) and
            HSCodes governing your access to and use of the HSCodes Material (as
            defined below). By entering into this Agreement, and/or by accessing
            or using the HSCodes Material, you expressly acknowledge that you
            have read, understood, and agree to be bound by this Agreement. This
            Agreement applies to any person or entity who access or use the
            HSCodes Material. HSCodes reserves the right, at its sole
            discretion, to change, modify, add or remove portions of this
            Agreement, at any time, by posting changes to this page. Your
            continued access to or use of the HSCodes Material after such
            posting confirms your consent to be bound by this Agreement, as
            amended.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '0pt'
          }}
        >
          <span
            style={{
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            IN ACCORDANCE WITH SECTION 4 BELOW, THE HSCODES SERVICES WILL BE
            PROVIDED TO YOU FOR NO FEE DURING THE CALENDAR YEAR 2022.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '0pt'
          }}
        >
          <span
            style={{
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE HSCODES
            MATERIAL. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND
            CONDITIONS OF THIS AGREEMENT, YOU MAY NOT ACCESS OR USE THE HSCODES
            MATERIAL.
          </span>
        </p>
        <ol
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '14pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Definitions.&nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    API Call
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means a harmonized schedule classification application
                    programming interface call using non-human-validated
                    algorithms.&nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Company Services
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means Company’s products or services, including any online
                    platform and mobile application, utilizing the HSCodes
                    Services.&nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Data
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means the data output (i.e. harmonized schedule tariff
                    codes or numbers) obtained via API Calls or other means and
                    made available by HSCodes under the Agreement.&nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Documentation
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means the documentation that HSCodes makes generally
                    available to its customers and any other documentation made
                    available to Company in any form related to the HSCodes
                    Material.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Effective Date
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means the date this Agreement is accepted and agreed to by
                    Company.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Fees
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means the fees payable to HSCodes under this Agreement, as
                    further described in Section 4.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    HSCodes Material
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means the HSCodes Services, the HSCodes Technology, and
                    the Documentation.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    HSCodes Services
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means the service of providing a harmonized schedule
                    tariff classification code or number based on Company input
                    via API Calls.&nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    HSCodes Technology
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means HSCodes’ application programming interface (API) or
                    software development kit (SDK) used to provide the HSCodes
                    Services.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Intellectual Property Rights
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means all patent, copyright, trademark, trade secret,
                    rights in know-how, designs, and other proprietary and
                    intellectual property rights recognized in any jurisdiction
                    worldwide, including moral rights.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Marks
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means a party’s name, logo, trade names, and trademarks.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Taxes
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means all local, state, federal, and foreign taxes,
                    levies, or duties of any nature.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Term
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” is as defined in Section 10.1.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    “
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Usage Data
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    ” means any anonymized and aggregated data that is derived
                    from Company’s access or use of the HSCodes Services, that
                    in no way identifies or refers to Company, and any
                    statistical or other analysis, information, or data based on
                    or derived from the foregoing. &nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                The HSCodes Material.
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    License Grant
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;During the Term and subject to Company’s compliance
                    with this Agreement, HSCodes hereby provides Company with a
                    limited, non-exclusive, non-transferable, non-sublicensable
                    right to use the HSCodes Services solely for the purpose of
                    affixing &nbsp;harmonized schedule tariff codes or numbers
                    on shipping labels and pre-clearance electronic messages for
                    its customers. &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Unauthorized Access
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Company may not share its log-in information or
                    access credentials with any third party and Company will
                    prevent unauthorized use or access to the HSCodes Material.
                    &nbsp;Company will notify HSCodes immediately of any known
                    or suspected unauthorized use of the HSCodes Material, or
                    breach of their security, and will use best efforts to stop
                    said unauthorized use or breach.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.3800000000000001',
                marginTop: '0pt',
                marginBottom: '10pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Company’s Responsibilities.
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '10pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Restrictions
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Company shall not and shall ensure that any third
                    parties accessing the Data shall not: (i) copy, modify, or
                    create derivative works of the HSCodes Material; (ii)
                    reverse engineer, disassemble, decompile, modify, or alter
                    the HSCodes Material; (iii) remove, circumvent, disable,
                    damage, or otherwise interfere with or disrupt the integrity
                    or performance of the HSCodes Material or any
                    security-related features therein; (iv) input, upload,
                    transmit, or otherwise provide to or through the HSCodes
                    Material, any information or materials that are unlawful or
                    injurious, or contain, transmit, send, or store material
                    containing harmful computer code, files, scripts, agents, or
                    programs; (v) rent, lease, loan, resell, sublicense,
                    distribute, monetize, or otherwise commercially exploit or
                    make available the HSCodes Material to any third party
                    (other than the Data to its customers in accordance with
                    Section 2.1 and subject to the restrictions set forth in
                    this Section 3.1); (vi) provide database services to any
                    third party using the Data; or (vii) use the HSCodes
                    Material or the Data as a basis to compete with HSCodes or
                    its business. &nbsp;Company is responsible for all acts and
                    omissions of its customers in connection with such
                    customer’s use of the Data, if any. &nbsp;HSCodes may
                    terminate this Agreement with immediate effect, in whole or
                    in part, with or without notice, if Company breaches this
                    Section 3.2.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '10pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Information
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . HSCodes may collect Usage Data and the Company consents to
                    such collection by HSCodes and to HSCodes’ use of such
                    information for its internal business purposes, such as to
                    improve the accuracy of the HSCodes Technology. HSCodes
                    categorization efforts require and depend on Company to
                    provide HSCodes with accurate information including accurate
                    product URLs, product descriptions, product destination or
                    jurisdiction, and training data to improve data results.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp; &nbsp; &nbsp; &nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Fees and Payment.
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Fees
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Company may use the HSCodes Material for no Fees
                    during the calendar year 2022. &nbsp;Following such time,
                    Company will pay the Fees specified in an Order Form, if
                    applicable. &nbsp;All Fees are quoted in United States
                    Dollars and are non-refundable. &nbsp;All Fees are payable
                    and due thirty (30) days from the invoice date. &nbsp;Any
                    payment not received by the due date may accrue, at HSCodes’
                    discretion, late charges at the rate of one and a half
                    percent (1.5%) of the outstanding balance per month, or at
                    the maximum rate permitted by law, whichever is lower, from
                    the date such payment was due until the date fully paid.
                    &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Taxes
                  </span>
                  <span
                    style={{
                      fontSize: '10pt',
                      fontFamily: 'Cambria,serif',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Fees are exclusive of all Taxes and Company is responsible
                    for payment of all Taxes, excluding only those based on
                    HSCodes’ income. &nbsp;If HSCodes has the legal obligation
                    to pay or collect Taxes for which Company is responsible
                    pursuant to this Section 4.2, the appropriate amount will be
                    invoiced to and paid by Company, unless Company provides
                    HSCodes with a valid tax exemption certificate authorized by
                    the appropriate taxing authority. &nbsp;Company will
                    indemnify, defend, and hold HSCodes, its officers,
                    directors, consultants, employees, successors, and assigns
                    harmless from all claims and liability arising from
                    Company’s failure to report or pay any such Taxes.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Audit
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Upon HSCodes’ request, Company shall certify in a
                    signed writing that its use of the HSCodes Material and the
                    Data is in full compliance with the terms of the Agreement.
                    &nbsp;HSCodes, or its authorized representative, may, upon
                    ten (10) days’ prior notice, inspect and audit Company’s
                    records and use of the HSCodes Material and the Data to
                    confirm its compliance with the Agreement. &nbsp;All such
                    inspections and audits will be conducted during regular
                    business hours and in a manner that does not unreasonably
                    interfere with Company’s business activities. &nbsp;Company
                    is responsible for such audit costs only in the event the
                    audit reveals that Company’s use is in violation of the
                    Agreement.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp; &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Proprietary Rights.&nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Company Intellectual Property
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;As between Company and HSCodes, Company owns all
                    right, title, and interest in and to: (i) the Company Marks
                    and all product names associated with the Company Services;
                    (ii) the Company Services and all derivative works thereof;
                    (iii) any other documents, user interfaces, technology,
                    know-how, trade secrets, designs, inventions, and other
                    tangible or intangible technical material or information
                    used to provide the Company Services other than the HSCodes
                    Materials, and/or conceived, inferred, or developed as part
                    of the Company Services; (iv) Company’s Confidential
                    Information; and (v) all Intellectual Property Rights in the
                    foregoing. &nbsp;Except as expressly set forth in this
                    Agreement, no license to or other rights to any of the
                    foregoing are granted to HSCodes, and all such rights are
                    hereby expressly reserved.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    HSCodes Intellectual Property
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;As between Company and HSCodes, HSCodes owns all
                    rights, title, and interest in and to: (i) the HSCodes Marks
                    and all product names associated with the HSCodes Services;
                    (ii) the HSCodes Material, and all derivative works thereof;
                    (iii) any other documents, user interfaces, technology,
                    source code, algorithms, databases, know-how, trade secrets,
                    designs, inventions, and other tangible or intangible
                    technical material or information used to provide the
                    HSCodes Material, and/or conceived, inferred, or developed
                    as part of or with the use of the HSCodes Material; (iv)
                    HSCodes’ Confidential Information; and (v) all Intellectual
                    Property Rights in the foregoing. &nbsp;Except as expressly
                    set forth in this Agreement, no license to or other rights
                    in the foregoing are granted to Company, and all such rights
                    are hereby expressly reserved. If Company or any of
                    Company’s employees, contractors, and agents sends or
                    transmits any communications or materials to HSCodes by
                    mail, email, telephone, or otherwise, suggesting or
                    recommending changes to the HSCodes Materials, including
                    without limitation, new features or functionality relating
                    thereto, or any comments, questions, suggestions, or the
                    like ("Feedback"), all such Feedback is and will be treated
                    as non-confidential. Company hereby assigns to HSCodes on
                    Company’s behalf, and on behalf of Company’s employees,
                    contractors, and agents, all right, title, and interest in,
                    and HSCodes is free to use, without any attribution or
                    compensation to Company or any third party, any ideas,
                    know-how, concepts, techniques, or other intellectual
                    property rights contained in the Feedback, for any purpose
                    whatsoever, although HSCodes is not required to use any
                    Feedback.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Confidential Information.&nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Confidential Information
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;As used herein, “Confidential Information” means all
                    non-public information disclosed by HSCodes(the “Disclosing
                    Party”) to the Company (the “Receiving Party”), whether
                    orally or in writing, that is designated as “Confidential,”
                    “Proprietary,” or the like and/or information that the
                    Receiving Party should reasonably understand as being
                    confidential or proprietary to the Disclosing Party given
                    the nature of the information and the circumstances of the
                    disclosure, including without limitation, this Agreement
                    (which will be deemed Confidential Information of both
                    parties), business and marketing plans, financial
                    information, technology and technical information, designs,
                    and business processes. In the case of HSCodes, Confidential
                    Information shall include the HSCodes Materials.
                    &nbsp;Confidential Information will not include any
                    information that: (i) is or becomes generally known to the
                    public without the Receiving Party's breach of any
                    obligation owed to the Disclosing Party; (ii) was in the
                    rightful possession or known by the Receiving Party prior to
                    receipt from the Disclosing Party without breach of any
                    obligation owed to the Disclosing Party; (iii) is received
                    from a third party who obtained such Confidential
                    Information without such third party's breach of any
                    obligation owed to the Disclosing Party; or (iv) was
                    independently developed by the Receiving Party without the
                    Receiving Party's breach of any obligation owed to the
                    Disclosing Party or its use of the other party’s
                    Confidential Information.&nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Protection
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;the Receiving Party will not disclose the Disclosing
                    Party’s Confidential Information, or use the Disclosing
                    Party’s Confidential information, for any purpose other than
                    to perform its obligations or exercise its rights under this
                    Agreement, and will protect the confidentiality of the
                    Confidential Information of the Disclosing Party in the same
                    manner that it protects the confidentiality of its own
                    proprietary and confidential information of like kind, but
                    in no event will either party exercise less than reasonable
                    care in protecting such Confidential Information. &nbsp;The
                    Receiving Party may disclose Confidential Information to its
                    employees, agents, contractors, and other representatives
                    having a legitimate need to know such information to perform
                    its obligations under this Agreement, provided that such
                    representatives are bound to confidentiality obligations no
                    less protective of the Disclosing Party than this Section 6
                    and that the Receiving Party remains responsible for
                    compliance by any such representative with the terms of this
                    Section 6. &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Compelled Disclosure
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;If the Receiving Party is compelled by law to
                    disclose Confidential Information of the Disclosing Party,
                    it will provide the Disclosing Party with prior written
                    notice of such compelled disclosure and reasonable
                    assistance (at Disclosing Party's cost) if the Disclosing
                    Party wishes to contest the disclosure. &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Remedies
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;If the Receiving Party discloses or uses (or
                    threatens to disclose or use) any Confidential Information
                    of the Disclosing Party in breach of this Section 6, the
                    Disclosing Party will have the right, in addition to any
                    other remedies available to it, to seek injunctive relief to
                    enjoin such acts, it being specifically acknowledged by the
                    parties that any other available remedies are inadequate.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp; &nbsp; &nbsp; &nbsp;Representations and Warranties;
                Disclaimer.&nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Mutual Warranties
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Each party represents and warrants that: (i) it is
                    duly organized, validly existing, and in good standing under
                    the laws of its jurisdiction of incorporation; (ii) it has
                    full right, power, and authority to enter into and perform
                    its obligations under this Agreement; and (iii) this
                    Agreement constitutes a valid and binding obligation of such
                    party and will be enforceable against such party in
                    accordance with its terms. &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    By Company
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Company represents and warrants that its use of the
                    HSCodes Material and the Data will not violate any
                    applicable law, rule, or regulation, infringe any third
                    party’s intellectual property, privacy, or publicity right,
                    or cause a breach of any agreement with any third party.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Disclaimer of Warranties
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;THE HSCODES MATERIAL, ALL RELATED SERVICES, AND THE
                    DATA ARE PROVIDED “AS IS.” &nbsp;HSCODES MAKES NO
                    REPRESENTATION OR WARRANTY OF ANY KIND WHETHER EXPRESS,
                    IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR
                    STATUTORY, AS TO ANY MATTER WHATSOEVER. &nbsp;HSCODES
                    EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY,
                    ACCURACY, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES
                    ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE.
                    &nbsp;HSCODES DOES NOT WARRANT THAT THE HSCODES MATERIAL OR
                    THE DATA IS ERROR-FREE,THAT THE HSCODES MATERIAL WILL BE
                    SECURE OR UNINTERRUPTED, THAT THE RESULTS OF THE USE THEREOF
                    WILL BE CORRECT OR ACCURATE OR THAT THE COMPANY OR ITS
                    CUSTOMERS WILL NOT BE ASSESSED ANY TAXES, FEES OR PENALTIES
                    AS A RESULT OF THE USE THEREOF. &nbsp;HSCODES EXERCISES NO
                    CONTROL OVER AND EXPRESSLY DISCLAIMS ANY LIABILITY RELATED
                    TO, ARISING OUT OF, OR BASED UPON ANY THIRD-PARTY SITES IN
                    RELATION TO THE HSCODES SERVICES OR THE DATA.
                  </span>
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Cambria,serif',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    &nbsp;THE&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    COMPANY HEREBY AGREES THAT HSCODES IS NOT AND WILL NOT BE
                    LIABLE FOR ANY LOSSES RESULTING OR ARISING OUT OF INACCURATE
                    OR UNAVAILABLE DATA OR HARMONIZED SCHEDULE TARIFF CODES OR
                    ANY INTERRUPTION IN THE HSCODES SERVICES AND THAT THE
                    HSCODES SERVICES ARE NOT COVERED BY ANY GUARANTEE OF SERVICE
                    OR COMPENSATION OF ANY SORT.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Indemnification.
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Indemnification
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;The Company will defend, indemnify, and hold
                    harmless HSCodes, its affiliates and its and their
                    respective officers, directors, consultants, employees,
                    successors, and assigns (“HSCodes Indemnitees”) against any
                    and all losses, damages, liabilities, and costs, including
                    reasonable attorneys’ fees (“Damages”) incurred by HSCodes
                    Indemnitees that arise out of or relate to any claim, suit,
                    action, or proceeding (“Claims”) that arises out of or
                    relates to: (i) Company’s (or, as applicable, its
                    customers’) use or misuse of the HSCodes Materials or the
                    Data ; (ii) Company’s (or, as applicable, its customers’)
                    breach of Sections 2, 3, 6, or 7 of this Agreement ; and
                    (iii) in the event Company provides any material to HSCodes,
                    infringement of an Intellectual Property Right by such
                    material as used by HSCodes in accordance with this
                    Agreement.&nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Limitations of Liability.
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Consequential Damages Exclusion
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;EXCEPT FOR: (i) A BREACH BY THE COMPANY OF ITS
                    CONFIDENTIALITY OBLIGATIONS IN SECTION 6; AND (ii) THE ITS
                    INDEMNIFICATION OBLIGATIONS IN SECTION 8 (COLLECTIVELY, THE
                    “EXCLUSIONS”), NEITHER PARTY MAY BE HELD LIABLE FOR ANY LOSS
                    OF PROFITS, LOSS OF USE, LOSS OF GOOD WILL, BUSINESS
                    INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, LOSS OF
                    CONTENT OR DATA, COST OF COVER, OR INDIRECT, PUNITIVE,
                    SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND
                    ARISING OUT OF THIS AGREEMENT. &nbsp;&nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '10pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Limitation of Liability
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;EXCEPT FOR THE EXCLUSIONS, NEITHER PARTY’S MAXIMUM
                    CUMULATIVE LIABILITY ARISING FROM OR RELATED TO THIS
                    AGREEMENT FOR ANY CAUSE WHATSOEVER, REGARDLESS OF THE FORM
                    OF ANY CLAIM OR ACTION, WHETHER BASED IN CONTRACT, TORT, OR
                    ANY OTHER LEGAL THEORY, WILL EXCEED THE GREATER OF: (i) THE
                    AGGREGATE FEES PAID OR PAYABLE BY COMPANY TO HSCODES IN THE
                    TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE
                    OCCURRENCE OF THE EVENT GIVING RISE TO THE CLAIM; AND (ii)
                    FIVE THOUSAND DOLLARS ($5,000.00). &nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Term &amp; Termination.
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Term
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;The term of this Agreement will commence on the
                    Effective Date and continue until either party provides at
                    least thirty (30) days’ prior written notice to the other
                    party of its intent to terminate this Agreement (the
                    “Term”). &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Mutual Termination
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Either party may terminate this Agreement with
                    immediate effect, in whole or in part, by giving the other
                    party prior written notice, if the other party: (i) commits
                    a material breach of any of its obligations under this
                    Agreement (ii) becomes insolvent or is generally unable to
                    pay, or fails to pay, its debts as they become due; (iii)
                    files or has filed against it, a petition for voluntary or
                    involuntary bankruptcy or otherwise becomes subject,
                    voluntarily or involuntarily, to any proceeding under any
                    domestic or foreign bankruptcy or insolvency law; (iv) makes
                    or seeks to make a general assignment for the benefit of its
                    creditors; (v) applies for or has appointed a receiver,
                    trustee, custodian, or similar agent appointed by order of
                    any court of competent jurisdiction to take charge of or
                    sell any material portion of its property or business; or
                    (vi) has wound up or liquidated its business, voluntarily or
                    otherwise. &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Effects of Termination
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Upon expiration or termination of this Agreement:
                    (i) Company will cease all use of the HSCodes Material and
                    the Data and will delete and destroy the Data and all copies
                    of the Documentation in its possession or control; (ii)
                    Company will be responsible for payment of all Fees due to
                    HSCodes for any period prior to the effective date of such
                    termination; and (iii) the Receiving Party will return or
                    destroy (and provide certification of such deletion upon
                    request) any of the Disclosing Party’s Confidential
                    Information then in its possession.&nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Survival
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Sections 1, 3.2, 4, 5, 6, 7.4, 8, 9, 10.4, 10.5, and
                    11 (together with all other provisions, including without
                    limitation all attachments hereto, that may be reasonably
                    interpreted as surviving termination or expiration of this
                    Agreement) will survive the termination or expiration of
                    this Agreement.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
              marginLeft: '-18pt'
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '12pt'
              }}
            >
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp; &nbsp; &nbsp; Miscellaneous.
              </span>
              <span
                style={{
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;&nbsp;
              </span>
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '48px'
              }}
            >
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Attribution and Publicity
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp; &nbsp;Either party may, upon the other party’s
                    prior consent in each instance, use such other party’s Marks
                    for marketing and publicity purposes. &nbsp;All such use and
                    display of a party’s Marks will be in accordance with such
                    party’s trademark guidelines if provided to the other party.
                    &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Export Regulation
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . Company shall comply with all laws administered by OFAC or
                    any other governmental entity imposing economic sanctions
                    and trade embargoes (“Economic Sanctions Laws”) against
                    designated countries, regimes, entities, and persons
                    (collectively, "Embargoed Targets"). Without limiting the
                    generality of the foregoing, Company shall not (a) directly
                    or indirectly export, re-export, transship, transfer, or
                    otherwise deliver the HSCodes Materials or any portion of
                    the HSCodes Materials to Embargoed Targets or (b) broker,
                    finance, or otherwise facilitate any transaction in
                    violation of any Economic Sanctions Law.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    No Waiver
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp; &nbsp;The failure of a party to enforce any
                    provision of this Agreement will not be construed to be a
                    waiver of the right of such party to thereafter enforce that
                    provision or any other provision or right.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Severability
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;In the event that any provision of this Agreement is
                    determined to be invalid, illegal, or unenforceable by a
                    court of competent jurisdiction, the rest of the Agreement
                    will remain in full force and effect.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Governing Law
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;This Agreement will be interpreted, construed, and
                    enforced in all respects in accordance with the laws of the
                    State of New York, without reference to its choice of law
                    rules. &nbsp;Each party hereby irrevocably consents to the
                    exclusive jurisdiction and venue of the federal, state, and
                    local courts in New York City, New York in connection with
                    any action arising out of or in connection with this
                    Agreement. &nbsp;The provisions of the United Nations
                    Convention on the International Sale of Goods do not apply
                    to this Agreement.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Entire Agreement
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;This Agreement constitutes the entire agreement and
                    supersedes all prior or contemporaneous oral or written
                    agreements regarding the subject matter hereof. &nbsp;In the
                    event of a conflict between the terms of this Agreement and
                    the terms of any Schedule, the terms of this Agreement
                    exclusive of the Schedule will control. &nbsp;Any purchase
                    order, written terms or conditions, or other document that
                    Company sends to HSCodes (whether prior to or after
                    acceptance of this Agreement by the Company) that contains
                    terms that are different from, in conflict with, or in
                    addition to the terms of this Agreement are hereby rejected
                    and will be void and of no effect. &nbsp;
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Relationship
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;HSCodes and Company are independent contractors and
                    this Agreement will not establish any relationship of
                    partnership, joint venture, employment, franchise, or agency
                    between HSCodes and Company. &nbsp;Neither party has any
                    right or authority to assume or create any obligations of
                    any kind or to make any representation or warranty on behalf
                    of the other party, whether express or implied, or to bind
                    the other party in any respect whatsoever. &nbsp;This
                    Agreement does not confer any benefits on any third party
                    unless expressly stated therein.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Force Majeure
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Except for Company’s payment obligations, neither
                    party will be liable for failure to perform or delay in
                    performing any obligation under this Agreement if such
                    failure or delay is due to fire, flood, earthquake, strike,
                    war (declared or undeclared), embargo, blockade, legal
                    prohibition, governmental action, riot, insurrection,
                    epidemic, damage, destruction, power outage, telephone
                    outage, internet access provider failure, or any other
                    similar cause beyond its control.
                  </span>
                </p>
              </li>
              <li
                aria-level={2}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '10.5pt',
                  fontFamily: '"Times New Roman"',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                  marginLeft: '-54pt',
                  paddingLeft: '9pt'
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.2',
                    textAlign: 'justify',
                    marginTop: '0pt',
                    marginBottom: '12pt'
                  }}
                >
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'underline',
                      WebkitTextDecorationSkip: 'none',
                      textDecorationSkipInk: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    Assignment
                  </span>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      fontFamily: '"Times New Roman"',
                      color: '#000000',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    . &nbsp;Company may not assign or transfer this Agreement or
                    its rights herein, in whole or in part, by operation of law
                    or otherwise, without HSCodes’ prior written consent.
                    HSCodes may assign this Agreement at any time without notice
                    or consent.
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span
            style={{
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            Notices
          </span>
          <span
            style={{
              fontSize: '10.5pt',
              fontFamily: '"Times New Roman"',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            . &nbsp;Any notice required or permitted by this Agreement will be
            in writing and will be sent by facsimile, email, courier, or
            personal delivery, addressed to the other party at the above address
            or at such other address for which such party gives notice
            hereunder. &nbsp;Notwithstanding the foregoing, any notices,
            agreements, disclosures, or other communications sent to Company by
            HSCodes electronically will satisfy any legal communication
            requirements, including that the communication be in writing.
            &nbsp;Notice is effective upon receipt. &nbsp;
          </span>
        </p>
      </div>
    </Modal>
  ) : (
    <></>
  )
}

export default EndUserTerms
