import styles from './CSVImport.module.scss'
import {
  FileUploader,
  MultiSelect,
  TextInput,
  Button,
  FileUploaderItem,
  Link
} from 'carbon-components-react'
import { useState } from 'react'
import { getAuthData } from '../../../lib/util'
import '../../../assets/variables.scss'
import DownloadTemplateModal from '../../DownloadTemplateModal/DownloadTemplateModal'
import { Warning16 } from '@carbon/icons-react'
import { setErrorNotifications } from '../../ErrorNotificationsProvider/action'
import { useStore } from '../../ErrorNotificationsProvider/ErrorNotificationsProvider'
import SelectedRegions from '../../SelectedRegions/SelectedRegions'

const CSVImport = ({
  file,
  fileName,
  setFile,
  setFileName,
  setStep,
  selectedItems,
  setSelectedItems,
  user,
  regions
}: any) => {
  const authData = getAuthData(user)
  const [status, setStatus] = useState<'uploading' | 'edit'>('uploading')
  const [isValid, setIsValid] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const { dispatch } = useStore()
  const [, setValidExtension] = useState(false)

  const handleValidExtension = (fileName: any) => {
    if (fileName.endsWith('.csv') || fileName.length === 0) {
      setValidExtension(true)
    } else if (!fileName.endsWith('.csv')) {
      dispatch(
        setErrorNotifications({
          title: 'File upload error:',
          subtitle: 'File type not recognized. Please upload .csv file only.',
          open: true
        })
      )
    }
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <div className={styles.root}>
      <h3>Interactive Classifier - CSV Upload</h3>
      <p>
        This classifier will help you find tariff codes (HS codes) for items
        listed in a file. <br /> Start by uploading a .csv formatted file.
      </p>
      <div className={styles.formContainer}>
        {/* <div className={styles.multiSelect}>
          <MultiSelect
            titleText="Destination Region(s)"
            useTitleInItem={false}
            label={
              selectedItems.length > 0
                ? `${selectedItems.length} ${
                    selectedItems.length > 1 ? 'regions' : 'region'
                  } selected `
                : 'Choose one or more options'
            }
            id="region"
            initialSelectedItems={selectedItems ? selectedItems : []}
            items={regions}
            itemToString={(item: any) => (item ? item.text : '')}
            onChange={(event) => setSelectedItems(event.selectedItems)}
          />
          <SelectedRegions selectedItems={selectedItems} />
        </div> */}
        <div>
          <p className={styles.fileUploadTitle}>Upload files</p>
          {!authData.account_type ||
            (authData.account_type !== 'pro' && (
              <p style={{ color: '$main' }}>
                Max process quantity {authData?.limit || 100} items per file.
                Only .csv files are supported.
              </p>
            ))}
        </div>
        {!file && (
          <div className={styles.fileUploadContainer}>
            <FileUploader
              buttonLabel="Upload .csv File"
              accept={['.csv']}
              buttonKind="ghost"
              filenameStatus={status}
              onDelete={() => {
                setFile(null)
                setFileName('')
              }}
              iconDescription="upload"
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  setStatus('edit')
                  setFile(event.target.files[0])
                  const name = event.target.files[0].name
                  setFileName(name)
                  setValidExtension(true)
                  if (!event.target.files[0].name.endsWith('.csv')) {
                    dispatch(
                      setErrorNotifications({
                        title: 'File upload error:',
                        subtitle:
                          'File type not recognized. Please upload .csv file only.',
                        open: true
                      })
                    )
                  }
                }
              }}
            />
          </div>
        )}
        {file && (
          <div className={styles.fileContainer}>
            <div className={styles.fileUploaderItem}>
              <FileUploaderItem
                name={file.name}
                iconDescription="Clear file"
                status="edit"
                onDelete={() => {
                  setFile(null)
                  setFileName('')
                }}
                onClick={() => handleValidExtension(fileName)}
                invalid={!fileName.endsWith('.csv')}
              />
            </div>
            <div className={styles.renameFile}>
              <TextInput
                className={styles.fileNameInput}
                id="search-input"
                size="lg"
                type="text"
                autoFocus
                required
                light={false}
                placeholder={fileName.length !== 0 ? `${fileName}` : ''}
                labelText="New File Name(Optional)"
                onChange={(event) => {
                  if (
                    /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g.test(
                      event.target.value
                    )
                  ) {
                    setIsValid(false)
                    setFileName(file.name)
                  } else if (event.target.value.length > 0) {
                    setFileName(event.target.value + '.csv')
                    setIsValid(true)
                  } else {
                    setFileName(file.name)
                  }
                }}
                invalid={!isValid}
                invalidText="Only alphanumeric characters are allowed"
                helperText="Rename file with alphanumeric characters only."
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.toolTipButton}>
        <div className={styles.bottonNextStep}>
          <Button
            className={styles.btnImportCSV}
            iconDescription="Next step"
            onClick={() => setStep(1)}
            disabled={!file || !selectedItems || selectedItems.length < 1}
            size="field"
          >
            {' '}
            Next Step
          </Button>
        </div>
        <Link className={styles.link} onClick={() => handleOpenModal()}>
          File Upload Help
          <Warning16 className={styles.icon} />
        </Link>
      </div>
      {openModal && (
        <DownloadTemplateModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  )
}
export default CSVImport
